import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import JobPostingOverview from '../components/JobPostingOverview';

export default function AboutUs({ data, location }) {
  const pageData = data.aboutUs;

  return (
    <Layout bodyClass="page-blog">
      <SEO
        title={pageData.frontmatter.title}
        image={null}
        url={location.href}
      />
      <div className="container pt-6">
        <div className="col-12 d-flex justify-content-between">
          <h1>{pageData.frontmatter.title}</h1>
        </div>
      </div>
      <br />
      <div className="container">
        <div className="col-12">
          <div dangerouslySetInnerHTML={{ __html: pageData.html }} />
        </div>
      </div>

      {pageData.frontmatter.aboutUs_image && (
        <div className="container">
          <div className="col-12 mb-8 mt-8">
            <div className="row justify-content-around">
              <img
                alt={pageData.frontmatter.title}
                style={{ width: '100%' }}
                src={pageData.frontmatter.aboutUs_image}
              />
            </div>
          </div>
        </div>
      )}

      <div className="strip">
        <JobPostingOverview />
      </div>
    </Layout>
  );
}

export const query = graphql`
  query {
    aboutUs: markdownRemark(
      fileAbsolutePath: { regex: "/content/about-us.md/" }
    ) {
      html
      frontmatter {
        title
        aboutUs_image
      }
      excerpt
    }
  }
`;
